import { graphql } from 'gatsby';
import * as React from 'react';
import { isEmpty } from 'lodash';
import ApartmentsContext from '../utils/context';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import { GlobalProvider } from '../context/GlobalContext';
import HomePageBanner from '../components/HomePageBanner';
import FeaturedPromotions from '../components/FeaturedPromotions';
import BookingForm from '../components/BookingForm';
import FeaturedSlider from '../components/FeaturedSlider';
import AmenitiesAndServices from '../components/AmenitiesAndServices';
import FeaturedPages from '../components/FeaturedPages';
import PromotionList from '../components/PromotionList';

export default function Page(props) {
  const { data, pageContext } = props;
  const { apartmentsHome } = data.umbraco;

  const { langCode } = pageContext;
  const settings = apartmentsHome.children.items.find(
    (item) => item.contentTypeAlias === 'apartmentsSettings',
  );
  const translations = settings.translations.map((value) => value.content);

  const getDir = () => (langCode === 'ar' ? 'rtl' : 'ltr');
  const memo = {
    langCode,
    cssClass: apartmentsHome.cssClass,
    translations,
  };

  const values = React.useMemo(() => memo, [memo]);

  return (
    <ApartmentsContext.Provider value={values}>
      <GlobalProvider>
        <Layout>
          <Seo page={apartmentsHome} lang={langCode} dir={getDir()} />
          <Header
            isHome={apartmentsHome.cssClass.indexOf('isHome') > -1}
            title="Home"
            settings={settings}
            currentPage={{
              id: apartmentsHome.id,
              url: apartmentsHome.url,
              udi: `umb://document/${apartmentsHome.id.replace(/-/g, '')}`,
            }}
            lang={langCode}
            dir={getDir()}
          />

          {isEmpty(apartmentsHome.apartmentsBody)
            ? null
            : apartmentsHome.apartmentsBody.map((item, index) => {
                if (isEmpty(item)) return null;
                const id = `${item.content.contentTypeAlias}_${index + 1}`;
                switch (item.content.contentTypeAlias) {
                  case 'apartmentsHomePageBanner':
                    return <HomePageBanner data={item.content} key={index} id={id} />;
                  case 'apartmentsFeaturedPromotions':
                    return (
                      <FeaturedPromotions data={item.content} key={index} id={id} dir={getDir()} />
                    );
                  case 'apartmentsBookingForm':
                    return <BookingForm data={item.content} key={index} id={id} dir={getDir()} />;
                  case 'apartmentsFeaturedSlider':
                    return (
                      <FeaturedSlider data={item.content} key={index} dir={getDir()} id={id} settings={settings} />
                    );
                  case 'apartmentsAmenitiesAndServices':
                    return (
                      <AmenitiesAndServices
                        data={item.content}
                        key={index}
                        id={id}
                        dir={getDir()}
                      />
                    );
                  case 'apartmentsFeaturedPages':
                    return <FeaturedPages data={item.content} key={index} id={id} />;
                  case 'apartmentsPromotionList':
                    return <PromotionList data={item.content} key={index} id={id} dir={getDir()} settings={settings} />;
                  default:
                    return null;
                }
              })}
          <Footer settings={settings} dir={getDir()} />
        </Layout>
      </GlobalProvider>
    </ApartmentsContext.Provider>
  );
}

export const query = graphql`
  query ($id: ID!, $lang: String!) {
    umbraco {
      apartmentsHome(id: $id, culture: $lang) {
        ...Home
      }
    }
  }
`;
